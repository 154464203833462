import $ from 'jquery';
import 'what-input';

import Swiper from 'swiper'; //lanceC
//import './klaro/config';
//import './klaro/klaro-no-css';
//import * as klaro from 'klaro/dist/klaro-no-css'

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
window.$ = $;

require('foundation-sites');

require('./vendor/jquery.easyPaginate');
//require('./klaro/klaro-no-css');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();
